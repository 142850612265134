import axios from "axios";
import Cookies from "js-cookie";
import { GetCategories, GetVideoList } from "@/services/config";
export default {
	data: () => {
		return {
			videoList: [],
			tokenVal: "",
			listInfo: [],
			currentPage: "",
			subTitleArr: [],
			isActive: ""
		};
	},
	created() {
		this.getVideoListData(1, "");
		this.getVideoType();
	},
	methods: {
		getVideoListData(pageNum, typeNum) {
			this.tokenVal = Cookies.get("token");
			this.axios
				.post(
					GetVideoList,
					{
						PageNo: pageNum,
						CateId: typeNum
					},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						this.listInfo = res.data.data;
						this.videoList = res.data.data.VideoList.Video;
					}
				});
		},
		getVideoType() {
			this.tokenVal = Cookies.get("token");
			this.axios
				.post(
					GetCategories,
					{},
					{
						headers: { token: this.tokenVal }
					}
				)
				.then(res => {
					if (res.data.code == 200) {
						let titleArr = JSON.parse(res.data.data);
						this.subTitleArr = titleArr.SubCategories.Category;
					}
				});
		},
		handleCurrentChange(val) {
			this.getVideoListData(val, this.isActive);
		},
		handleSizeChange() { },
		subTitle(index) {
			this.isActive = index;
			this.getVideoListData(1, index);
		}
	},
	filters: {
		// 视频标题
		setVideoTitle: function (value) {
			if (!value) return "";
			value = value.split(" ");
			return value[0];
		},
		// 视频作者
		setVideoAuthor: function (value) {
			if (!value) return "";
			value = value.split(" ");
			return value[1];
		},
		// 视频时间
		setVideoTime: function (value) {
			if (!value) return "";
			value = new Date() - value;
		}
	},
	components: {
		axios
	}
};
